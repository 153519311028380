export const scrollToBottom = function () {
  window.scrollTo(0, document.body.scrollHeight);
};

export const determineAppType = function () {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('t')) return 'messenger';
  if (urlParams.has('c')) return 'call';
  if (urlParams.has('r')) return 'virtualWaitingRoom';
  if (urlParams.has('v')) return 'virtualWaitingRoomVisit';
  return 'unknown';
};

export const isVwrFeatureEnabled = function () {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('r') || urlParams.has('v');
};

export const getInitials = function (string: string) {
  if (!string) return string;
  return string
    .split(' ')
    .map((x) => x[0].toUpperCase())
    .join('');
};

// Some browsers include the seconds with the 'short' timeStyle. This function
// standardizes the format so that it always looks like '10:55 AM'
export const dateToHourMinMeridiem = function (input: string | Date) {
  const date = typeof input === 'object' ? input : new Date(input);
  const timeString = date.toLocaleTimeString('en-US', {
    timeStyle: 'short',
  } as Intl.DateTimeFormatOptions);
  const [time, meridiem] = timeString.split(' ');
  const [hour, min] = time.split(':');
  return `${hour}:${min} ${meridiem}`;
};
