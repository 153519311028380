import React from 'react';
import classNames from 'classnames';

import styles from './DotsIndicator.module.css';

export function DotsIndicator({
  color,
  fullHeight = true,
  marginRight = 2,
  marginTop = 0,
  size = 8,
  speed = 1.5,
}: {
  color: string;
  fullHeight?: boolean;
  marginRight?: number;
  marginTop?: number;
  size?: number;
  speed?: number;
}) {
  const getFillStyle = (delay: number) => ({
    backgroundColor: color,
    animationDelay: `-${delay * (1 / speed)}s`,
    marginRight: `${marginRight}px`,
    marginTop: `${marginTop}px`,
    width: `${size}px`,
    height: `${size}px`,
  });

  return (
    <div className={classNames({ [styles.raiDots]: true, [styles.fullHeight]: fullHeight })}>
      <div className={styles.raiCircle} style={getFillStyle(0.3)} />
      <div className={styles.raiCircle} style={getFillStyle(0.2)} />
      <div className={styles.raiCircle} style={getFillStyle(0.1)} />
    </div>
  );
}

export default DotsIndicator;
