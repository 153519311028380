import React, { lazy, Suspense, useEffect } from 'react';

import DotsIndicator from './components/DotsIndicator';
import { determineAppType } from './util';
import './App.css';
const PatientCall = lazy(() => import('./PatientCall'));
const PatientMessenger = lazy(() => import('./PatientMessenger'));
const VirtualWaitingRoom = lazy(() => import('./PatientVirtualWaitingRoom'));

const APP_TYPE = determineAppType();

export const App = () => {
  useEffect(() => {
    if (APP_TYPE === 'call') {
      document.getElementsByTagName('BODY')[0].setAttribute('id', 'patient-video');
    } else {
      document.getElementsByTagName('BODY')[0].setAttribute('id', 'patient-message-anyone');
    }
  }, []);

  return (
    <Suspense fallback={<DotsIndicator color="#65659e" />}>
      {(APP_TYPE === 'messenger' || APP_TYPE === 'unknown') && <PatientMessenger />}
      {APP_TYPE === 'call' && <PatientCall />}
      {(APP_TYPE === 'virtualWaitingRoom' || APP_TYPE === 'virtualWaitingRoomVisit') && (
        <VirtualWaitingRoom />
      )}
    </Suspense>
  );
};

export default App;
